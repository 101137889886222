
const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
}

// Get method.
export function get(IP,url) {
    return fetch(`http://${IP}/${url}`, {
        method: 'GET',
        headers: headers
    })
        .then(response => {
            console.log("api", response)
            return response.json()
        })
        .then(response => {
            return response
        })
        .catch((error) => console.log(error));
}

// Post method.
export function post(IP, url, data) {
    return fetch(`http://${IP}/${url}`, {
        method: 'POST',
        headers: headers,
        body:JSON.stringify(data)
    })
        .then(response => {
            console.log("api", response)
            return response.json()
        })
        .then(response => {
            return response
        })
        .catch((error) => console.log(error));
}

export default { get, post };
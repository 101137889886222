import * as actionType from '../Actions/actionType';

// const ip = 'nagaplay.xyz';
// const ip =   'localhost:8081';
const ip = '148.72.212.210:8059';

const setIp = (state = ip , action) => {
    let newState;
    switch (action.type) {
        case actionType.SETIP:
            return newState = action.payload
    }
    return state
}

export default setIp;

import * as actionType from  '../Actions/actionType';

let credits = localStorage.getItem('credits');

const creditsReducer = (state = credits, action) => {
  let newState;
  switch (action.type) {
    case actionType.ADD_COUNTER:
      return newState = action.payload;
    case actionType.REMOVE_COUNTER:
      return newState = state - action.payload;
    default:
      return state
  }
}




export default creditsReducer;

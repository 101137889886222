import * as actionType from '../Actions/actionType';
let user = localStorage.getItem('userType');
const setUserType = (state = user , action) => {
    let newState;
    switch (action.type) {
        case actionType.SETUSER:
            return newState = action.payload
    }
    return state
}

export default setUserType;

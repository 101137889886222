import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { AuthorizedComponent } from 'react-router-role-authorization';

// import { renderRoutes } from 'react-router-config';
import './App.scss';
import { post } from '../src/assets/api/api';
import { connect } from 'react-redux';
import Loader from 'react-loader-spinner'
import { Redirect } from '@reach/router';
const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;


// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Dashboard = React.lazy(() => import('./views/Dashboard/Dashboard'));
const Login = React.lazy(() => import('./views/Pages/Login'));
const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTokenValid: false,
      isLoading: true,
      accessRole: '',
      isAccess: false
    };
    this.RedirectToDashboard = this.RedirectToDashboard.bind();
  }

  // Get login credentials

  role = localStorage.getItem('role');
  componentDidMount = () => {
    this.checkTokenValid();
  }

  checkTokenValid = () => {
    console.log(window.location.pathname)
    let token = localStorage.getItem('token');
    let name = localStorage.getItem('name');
    let userType = localStorage.getItem('userType');
    let role = localStorage.getItem('role');

    if (token && name && userType && role) {
      if (role == 'superadmin') { this.verifyToken(token, name, role); } else {
        this.verifyAccess(token, name, role);
      }
    } else {
      this.setState({ isLoading: false });
    }
  }

  // check USer have acces to open page.
  verifyAccess = (token, name, role) => {
    let id = window.location.pathname.split("/").pop();
    switch (window.location.pathname) {
      case '/dashboard':
      case '/admin/operators':
      case '/admin/dealers':
      case '/admin/subdealers':
      case '/admin/dealer/dealerHasOperators/' + id:
      case '/admin/subDealerHasOperators/' + id:
      case '/admin/records':
      case '/downloadexe':
        this.setState({ accessRole: 'admin' }, () => {
          this.checkPageAccess(token, name, role);
        });
        break;
      case '/dealer/operators':
      case '/dealer/operators/':
      case '/downloadexe/':
        this.setState({ accessRole: 'dealer' }, () => {
          this.checkPageAccess(token, name, role);
        });
        break;
      case '/':
        this.RedirectToDashboard();
        break;
      // default:
      //   window.location.pathname = '/';
      //   break;
    }
  }

  // check checkPageAccess.
  checkPageAccess = (token, name, role) => {
    if (this.role == this.state.accessRole) {
      this.verifyToken(token, name, role);
    } else {
      this.setState({isLoading: false})
    }
  }

  // Verify Token api.
  verifyToken = (token, name, role) => {
    try {
      post(this.props.host, 'api/autoLogin', { token, name, role }).then(result => {
        if (result && result.code == 'success') {
          this.setState({ isTokenValid: true, isLoading: false, isAccess: true })
        } else {
          this.setState({ isLoading: false })
        }
      })
    } catch (error) {
      this.setState({ isLoading: false});
      return false;
    }
  }



// Reditect to dashboard.
RedirectToDashboard = () => {
  if (this.role == 'superadmin') {
    window.location.pathname = '/superadmin/dashboard';
  } else {
    if (this.role == 'admin') {
      window.location.pathname = '/dashboard';

    } else {
      window.location.pathname = '/dealer/operators/';
    }
  }

  this.setState({isLoading: false, isAccess: true})

}


render() {
  return (
    <BrowserRouter>
      <React.Suspense fallback={loading()}>
        <Switch>
          {
            this.state.isLoading &&
            <div class="initialLoader">
              <Loader
                type="ThreeDots"
                color="#00BFFF"
                height={100}
                width={100}
              />
            </div>
          }
          {
            !this.state.isTokenValid ?
              <Route path="/" name="Home" component={Login} /> :
              !this.state.isAccess ? <Route path="/" name="Home" component={Login} /> :
                window.location.pathname == '/' ? this.RedirectToDashboard() :
                  <Route path="/" name="Home" render={props => <DefaultLayout {...props} />} />
          }
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  );
}
}

const mapStateToProps = (state) => {
  return {
    host: state.setIp,
  };
}
export default connect(mapStateToProps)(App);



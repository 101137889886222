import { combineReducers } from 'redux';
import creditsReducer from './creditsReducers';
import setUserType from './setUserType';
import setIp from './setIp';

const creditsApp = combineReducers({
    creditsReducer, setUserType, setIp
})

export default creditsApp
